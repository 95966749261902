import { RemixBrowser } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { startTransition, StrictMode } from "react";
import { hydrateRoot } from "react-dom/client";

Sentry.init({
  dsn: "https://215ab9d71261b9998769553bf5410158@o4505890278932480.ingest.sentry.io/4505920097026048",

  allowUrls: [/^https:\/\/gowindmill\.com/, /^https:\/\/staging\.gowindmill\.com/],

  /*
   * Capture Replay for 10% of all sessions,
   * plus for 100% of sessions with an error
   */
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});
